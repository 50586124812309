var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "盘点详情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (stocktaking) {
    return _c('div', {
      key: stocktaking.id,
      staticClass: "stock-record-container"
    }, [_c('van-cell', {
      attrs: {
        "title": "门店",
        "value": stocktaking.store.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "商品名称",
        "value": stocktaking.goods.product.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "盘点人",
        "value": stocktaking.user.name
      }
    }), stocktaking.begin_at ? _c('van-cell', {
      attrs: {
        "title": "开始时间",
        "value": stocktaking.begin_at
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "结束时间",
        "value": stocktaking.end_at || stocktaking.created_at
      }
    }), stocktaking.use_minutes || stocktaking.use_seconds ? _c('van-cell', {
      attrs: {
        "title": "用时",
        "value": stocktaking.use_minutes > 0 ? stocktaking.use_minutes + '分' : stocktaking.use_seconds + '秒'
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "盘点前库存",
        "value": stocktaking.before_stock
      }
    }), _c('van-cell', {
      attrs: {
        "title": "盘点后库存",
        "value": stocktaking.after_stock
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }