<template>
  <div>
    <my-nav-bar
      title="盘点详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="getList"
      >
        <div v-for="stocktaking in list" :key="stocktaking.id" class="stock-record-container">
          <van-cell title="门店" :value="stocktaking.store.name" />
          <van-cell title="商品名称" :value="stocktaking.goods.product.name" />
          <van-cell title="盘点人" :value="stocktaking.user.name" />
          <van-cell v-if="stocktaking.begin_at" title="开始时间" :value="stocktaking.begin_at" />
          <van-cell title="结束时间" :value="stocktaking.end_at || stocktaking.created_at" />
          <van-cell
            v-if="stocktaking.use_minutes || stocktaking.use_seconds"
            title="用时"
            :value="stocktaking.use_minutes > 0 ? stocktaking.use_minutes + '分' : stocktaking.use_seconds + '秒'"
          />
          <van-cell title="盘点前库存" :value="stocktaking.before_stock" />
          <van-cell title="盘点后库存" :value="stocktaking.after_stock" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'
import { getStockRecordDetail } from '@/api/stock-record'
export default {
  components: { myNavBar },
  data() {
    return {
      currentDate: new Date(),
      listQuery: {
        page: 0,
        date: this.$route.query.date || toDateString(new Date()),
        store_id: this.$route.query.store_id || '',
        goods_id: this.$route.query.goods_id
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getStockRecordDetail(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list < 20
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .stock-record-container {
    margin-bottom: 10px;
  }
  .stock-record-container:nth-last-child(2) {
    margin-bottom: 0!important;
  }
</style>
